.gjs-rte-toolbar {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px 2px rgba(0, 0, 0, 0.44);
    border-radius: 3px
}

.gjs-rte-action {
    font-size: 1rem;
    border-right: none;
    padding: 10px;
    min-width: 35px
}

.gjs-rte-actionbar {
    max-width: 600px;
    flex-wrap: wrap
}

.rte-hilite-btn {
    padding: 3px 6px;
    border-radius: 3px;
    background: rgba(210, 120, 201, 0.3)
}

.rte-color-picker {
    display: none;
    padding: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2), 0 2px 5px rgba(0, 0, 0, 0.34);
    border-radius: 5px;
    position: absolute;
    top: 55px;
    width: 250px;
    transition: all 2s ease
}

.rte-color-picker:before {
    content: "";
    position: absolute;
    top: -20px;
    left: 46%;
    border-width: 10px;
    border-style: solid
}

.rte-color-picker.dark {
    background: rgba(0, 0, 0, 0.8)
}

.rte-color-picker.dark:before {
    border-color: transparent transparent rgba(0, 0, 0, 0.75) transparent
}

.rte-color-picker.light {
    background: rgba(255, 255, 255, 0.75)
}

.rte-color-picker.light:before {
    border-color: transparent transparent rgba(255, 255, 255, 0.75) transparent
}

.rte-color-picker > div {
    width: 30px;
    display: inline-block;
    height: 30px;
    margin: 5px;
    border-radius: 100%;
    opacity: 0.7
}

.rte-color-picker > div:hover {
    opacity: 1
}

.picker-wrapper {
    padding: 20px
}