@media print {
    @page {
        /*size:  auto;   !* auto is the initial value *!*/
        /*margin: 0;*/
        size: A4;
        margin: 4mm;
    }

    html
    {
        background-color: #FFFFFF;
        margin: 0;  /* this affects the margin on the html before sending to printer */
    }

    body * {
        visibility: hidden;
    }

    #section-to-print, #section-to-print * {
        visibility: visible;
    }

    #section-to-print {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        background-color: white;
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 15px;
    }

    #section-to-print .MuiListItemText-multiline {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    #section-to-print .MuiListItem-dense {
        padding-top: 2px !important;
        padding-bottom: 2px !important;

        page-break-inside: avoid !important;
    }

    #non-printable { display: none; }
}